<template>
  <LoadingRights>
  <div class="fixed h-screen w-full">
    <div class="fixed top-0 z-30 w-full">
      <AppHeader />
    </div>
    <div class="grid grid-cols-8 desktop:grid-cols-6 h-full pt-14">
      <div class="col-span-1 overflow-y-auto">
        <sgdf-sidebar class="sidebar-desktop">
          <sl-details summary="Configuration" open>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParameters(meetingId!)">
                <sgdf-icon name="graduation-cap"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.meeting")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForParticipants(meetingId!)">
                <sgdf-icon name="person-circle-check"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.participants")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
          </sl-details>
          <sl-details summary="Préparation" open>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForTeams(meetingId!)">
                <sgdf-icon name="people-group"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.teams")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForDailyTasks(meetingId!)">
                <sgdf-icon name="list-check"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.daily-tasks")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForRooms(meetingId!)">
                <sgdf-icon name="people-roof"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.rooms")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForPlanning(meetingId!)">
                <sgdf-icon name="calendar"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.planning")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForLinks(meetingId!)">
                <sgdf-icon name="folder-open"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.docs")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
            <sgdf-sidebar-item v-if="hasModule('form', meetingId)">
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingFormsEdition(meetingId!)">
                <sgdf-icon name="clipboard-question"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.forms")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
          </sl-details>
          <sl-details summary="Exports" open>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForExports(meetingId!)">
                <sgdf-icon name="download"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.exports")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
          </sl-details>
          <sl-details summary="Administratif" open>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start" :to="RouteUtils.getMeetingParametersForOpinionReview(meetingId!)
                ">
                <sgdf-icon name="feather"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.opinion-review")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForTagada(meetingId!)">
                <sgdf-icon name="rotate"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.tagadasync")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
            <sgdf-sidebar-item>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForFormers(meetingId!)">
                <sgdf-icon name="chalkboard-user"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.formers")
                }}</span>
              </NuxtLink>
            </sgdf-sidebar-item>
          </sl-details>
        </sgdf-sidebar>
        <sgdf-sidebar class="sidebar-mobile">
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">{{ $t("label.parameters.meeting") }}</div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParameters(meetingId!)">
                <sgdf-icon name="graduation-cap"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.meeting")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">
                {{ $t("label.parameters.participants") }}
              </div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForParticipants(meetingId!)">
                <sgdf-icon name="person-circle-check"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.participants")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <hr />
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">{{ $t("label.parameters.teams") }}</div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForTeams(meetingId!)">
                <sgdf-icon name="people-group"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.teams")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">{{ $t("label.parameters.daily-tasks") }}</div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForDailyTasks(meetingId!)">
                <sgdf-icon name="list-check"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.daily-tasks")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">{{ $t("label.parameters.rooms") }}</div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForRooms(meetingId!)">
                <sgdf-icon name="people-roof"></sgdf-icon>
              </NuxtLink>
              <span class="ml-3 sidebar-item-label">{{
                $t("label.parameters.rooms")
              }}</span>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">{{ $t("label.parameters.planning") }}</div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForPlanning(meetingId!)">
                <sgdf-icon name="calendar"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.rooms")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">{{ $t("label.parameters.docs") }}</div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForLinks(meetingId!)">
                <sgdf-icon name="folder-open"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.docs")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <hr />
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">{{ $t("label.parameters.exports") }}</div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForExports(meetingId!)">
                <sgdf-icon name="download"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.exports")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <hr />
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">
                {{ $t("label.parameters.opinion-review") }}
              </div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start" :to="RouteUtils.getMeetingParametersForOpinionReview(meetingId!)
                ">
                <sgdf-icon name="feather"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.opinion-review")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">{{ $t("label.parameters.tagadasync") }}</div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForTagada(meetingId!)">
                <sgdf-icon name="rotate"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.tagadasync")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
          <sgdf-sidebar-item>
            <sl-tooltip>
              <div slot="content">{{ $t("label.parameters.formers") }}</div>
              <NuxtLink class="flex items-center justify-center desktop:justify-start"
                :to="RouteUtils.getMeetingParametersForFormers(meetingId!)">
                <sgdf-icon name="chalkboard-user"></sgdf-icon>
                <span class="ml-3 sidebar-item-label">{{
                  $t("label.parameters.formers")
                }}</span>
              </NuxtLink>
            </sl-tooltip>
          </sgdf-sidebar-item>
        </sgdf-sidebar>
      </div>
      <div class="col-span-7 desktop:col-span-5 overflow-y-auto bg-white">
        <slot></slot>
      </div>
    </div>
  </div>
</LoadingRights>
</template>

<style scoped>
@media (max-width: 700px) {
  .sidebar-item-label {
    display: none;
  }
}

@media (max-width: 1024px) {
  .sidebar-desktop {
    display: none;
  }

  .sidebar-mobile {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .sidebar-desktop {
    display: flex;
  }

  .sidebar-mobile {
    display: none;
  }
}

sl-details::part(header),
sl-details::part(content) {
  padding: var(--sl-spacing-small);
}

sl-details::part(content) {
  padding-top: 0;
}
</style>

<script setup lang="ts">
import "@sgdf/ui-library/sgdf-sidebar.js";
import "@sgdf/ui-library/sgdf-sidebar-item.js";
import "@sgdf/ui-library/sgdf-icon.js";

const meetingId = useMeetingId();
const hasModule = useModule();
</script>
